import React from 'react';
import {Center, Container, Text, Title} from "@mantine/core";
import {useStyles} from "./styles";

const PageOne = () => {
    const {classes} = useStyles();


    return (
        <Container className={classes.container}>
            <Center>
                <Title mt={20}>Hola amorcito ✨❤️</Title>
            </Center>
            <Text size={'xl'} mt={20}>Espero que en el momento que estes leyendo esto, te sientas feliz.</Text>
            <Text size={'xl'}>
                Porque seguramente yo lo haré al saber que estas leyendolo.
            </Text>
        </Container>
    );
};

export default PageOne;