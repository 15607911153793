import React from 'react';
import {Center, Container, Highlight, Image, Text, Title} from "@mantine/core";
import {useStyles} from "./styles";
import Us from './71C336AA-71B6-435F-B386-7576E749B2B7_1_105_c.jpeg'

const PageThree = () => {
    const {classes} = useStyles();

    return (
        <Container className={classes.container}>
            <Text size={'xl'} pt={30}>Pero sobre todo te amo a ti, a la persona que decidió mostrarme su mundo, y que
                decidió
                amarme cuando le mostré el mío.</Text>
            <Text size={'xl'} mt={10}>Espero que todos los buenos momentos que hemos pasado los recuerdes hoy y siempre
                con cariño, y que
                aquellos no tan buenos nos sirvan para ver que siempre hay cosas más importantes por las cuales
                luchar.</Text>
            <Center>
                <Highlight highlightColor={'blue'} mt={20} component={Title} highlight={['por nosotros.']} size={'xl'}
                           highlightStyles={{color: 'white'}}>
                    Y yo siempre elegiré luchar por ti, por lo nuestro; por nosotros.</Highlight>
            </Center>
            <Center mt={20}>
                <Title color={'black'}>Te amo<br/> -J 🐊</Title>
            </Center>
            <Center pb={20}>
                <Image src={Us} fit={"cover"}/>
            </Center>
        </Container>
    );
};

export default PageThree;