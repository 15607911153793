import './App.css';
import { useStyles } from "./styles";
import PageOne from "./PageOne.js";
import PageTwo from "./PageTwo.js";
import PageThree from "./PageThree.js";

function App() {
  const { classes } = useStyles();

  return (
    <div className={classes.bg}>
      <PageOne />
      <PageTwo />
      <PageThree />
    </div>
  );
}

export default App;
