import { createStyles } from "@mantine/core";

export const useStyles = createStyles(theme => ({
  container: {
    color: theme.colors.gray[8],
    backgroundColor: theme.colors.yellow[2],
  },

  bg: {
    backgroundColor: theme.colors.yellow[2],
  }
}))
