import React from 'react';
import {Container, Highlight, Text} from "@mantine/core";
import {useStyles} from "./styles";

const PageTwo = () => {
    const {classes} = useStyles();

    return (
        <Container className={classes.container} id={'page-two'} pt={20}>
            <Text size={'xl'}>Antes que nada mi amorcito, quiero decirte lo feliz y afortunado que me siento de que
                podamos compartir un pedazito de nuestra vida el uno con el otro</Text>
            <Highlight highlightColor={'blue'} component={Text} highlight={['cuando veo tu sonrisa']} size={'xl'}
                       highlightStyles={{color: 'white'}}>
                No hay momentos más felices para mi qué los que paso a tu lado, cuando veo tu sonrisa, cuando te ríes de
                alguna de todas las pendejadas que digo cuando estoy a tu lado aun y cuando se que la mayoría del tiempo
                no son tan graciosas o seguro sólo soy yo quejandome de todo como siempre 😆</Highlight>
            <Highlight highlight={[]} component={Text} size={'xl'} mt={10}>
                Pero no se trata solo del tiempo que paso contigo</Highlight>
            <Text size={'xl'} mt={10}>
                Me encanta que me achipiles y me trates como a un bebé incluso cuando es probablemente la descripción
                menos adecuada para mi.
            </Text>
            <Text size={'xl'} mt={10}>
                Me encanta como nos entendemos el uno al otro, las cosas que decimos y entendemos sin decir una sola
                palabra.
            </Text>
            <Text size={'xl'} mt={20}>
                Amo lo inteligente que eres.</Text>
            <Text size={'xl'}>Amo lo divertida que eres.</Text>
            <Text size={'xl'}>Amo tu sonrisa.</Text>
            <Text size={'xl'}>Amo tu cuerpo.</Text>
        </Container>
    );
};

export default PageTwo;